
import { Component, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required, numeric } from "vuelidate/lib/validators";

@Component
export default class MixinDiscountRateDefermentDaysEditorValidator extends Vue {
  @Validations()
  validations = {
    item: {
      //ジャンプアップ割引有効日数
      discountRateDefermentDays: { required, numeric },
    },
  };
  //---------------------------
  // methods
  //---------------------------

  /**
   * ジャンプアップ割引有効日数入力エラーメッセージを返します.
   */
  get discountRateDefermentDaysErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.discountRateDefermentDays?.$dirty) return errors;
    !this.$v.item.discountRateDefermentDays?.required &&
      errors.push("ジャンプアップ割引有効日数を入力してください.");
    !this.$v.item.discountRateDefermentDays?.numeric &&
      errors.push("数値を入力してください.");
    return errors;
  }
}
