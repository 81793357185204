










import SubPageTemplate from "@/components/templates/SubPageTemplate.vue";
import DiscountRateDeferementDaysEditor from "@/components/organisms/shop_setting/DiscountRateDefermentDaysEditor.vue";

export default {
  components: {
    SubPageTemplate,
    DiscountRateDeferementDaysEditor,
  },
};
